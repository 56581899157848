<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>记录查询</el-breadcrumb-item>
      <el-breadcrumb-item>异常记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pk_id"
            filterable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px 50px" for=""
          >车牌：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入车牌号"
            v-model.trim="query.plateNo"
            clearable
        /></label>
        <label style="margin: 10px 50px" for=""
          >订单号：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入订单号"
            v-model="query.orderNo"
            clearable
        /></label>
        <label style="margin: 10px" for=""
          >创建时间：<el-date-picker
            v-model="inTime"
            @change="inTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="outTime"
            @change="outTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
          >
          </el-date-picker
        ></label>
        <label style="margin: 10px" for=""
          >支付状态：
          <el-select
            v-model="query.payStatus"
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              :label="item.name"
              :value="item.code"
              v-for="(item, index) in payStatusdd"
              :key="index"
            ></el-option>
          </el-select>
        </label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="orderInfocx"
          >查询</el-button
        >
        <el-button size="small" @click="orderInfocz">重置</el-button>
        <el-button
          type="primary"
          size="small"
          :loading="isShowloading"
          @click="orderInfoexcel"
          >导出</el-button
        >
      </div>
      <!-- 表格 -->
      <div v-if="cashzje" style="text-align: right; font-weight: 600">
        订单总金额:{{ cashzje.total ? cashzje.total : '0' }}元，实际优惠抵扣:{{
          cashzje.coupon ? cashzje.coupon : '0'
        }}元，应收:{{
          cashzje.receivableMoney ? cashzje.receivableMoney : '0'
        }}元，实收:{{ cashzje.actually ? cashzje.actually : '0' }}元，微信:{{
          cashzje.wxPay ? cashzje.wxPay : '0'
        }}元，支付宝:{{ cashzje.alipay ? cashzje.alipay : '0' }}元，聚合支付:{{
          cashzje.ccb ? cashzje.ccb : '0'
        }}元，杭州城市大脑:{{
          cashzje.hangzhouCity ? cashzje.hangzhouCity : '0'
        }}元，杭州银行:{{
          cashzje.hzBank ? cashzje.hzBank : '0'
        }}元，现金支付:{{ cashzje.cash ? cashzje.cash : '0' }}元，ETC支付:{{
          cashzje.etc ? cashzje.etc : '0'
        }}元，P+R支付:{{ cashzje.pR ? cashzje.pR : '0' }}元
      </div>
      <div v-else style="text-align: center; font-weight: 600">
        订单金额统计中<i class="el-icon-loading"></i>
      </div>
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          ref="multipleTable"
          :data="tableData"
          @sort-change="sortchange"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="orderNo"
            label="订单号"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="plateNo"
            label="车牌号"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="orderTime"
            label="订单时间"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.orderTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="orderTotalMoney"
            label="订单金额"
            sortable="custom"
            width="110"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponMoney"
            label="优惠券金额"
            width="85"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponMoney"
            label="实际抵扣金额"
            width="95"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.orderTotalMoney > scope.row.couponMoney
                  ? scope.row.couponMoney
                  : scope.row.orderTotalMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="actuallyPayMoney"
            label="实收金额"
            width="75"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="payStatus"
            label="支付状态"
            width="75"
          >
            <template slot-scope="scope">
              <span>{{
                findQueryzfzt(
                  scope.row.payStatus,
                  scope.row.isHzcityPay,
                  payStatusdd
                )
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="260"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="queryPayment(scope.row.id)"
                size="mini"
                >支付日志</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 支付日志 -->
    <el-dialog
      title="查看支付日志"
      :visible.sync="isShowPayment"
      width="70%"
      v-el-drag-dialog
    >
      <el-table
        :data="queryPaymentList"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" label="编号" width="70"></el-table-column>
        <el-table-column
          prop="payOrderNo"
          label="订单号"
          width="140"
        ></el-table-column>
        <el-table-column
          prop="orderPayMoney"
          label="支付金额"
        ></el-table-column>
        <el-table-column prop="changeMoney" label="找零"></el-table-column>
        <el-table-column prop="paidType" label="支付方式">
          <template slot-scope="scope">
            <span v-if="scope.row.paidType">{{
              findQuery(scope.row.paidType, payment)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payScene" label="支付场景">
          <template slot-scope="scope">
            <span v-if="scope.row.payScene">{{
              findQuery(scope.row.payScene, payScene)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payStatus" label="支付状态">
          <template slot-scope="scope">
            <span v-if="scope.row.payStatus">{{
              findQuery(scope.row.payStatus, payStatus)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="returnTime" label="支付时间" width="140">
          <template slot-scope="scope">
            <span>{{
              scope.row.returnTime ? conversionTime(scope.row.returnTime) : ''
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  newOrderInfo,
  exportAbnormalOrderinfo,
  orderQueryDataBill,
  selectByPayLogtk,
} from '@/api/record.js'
import { findPkInfoSelect } from '@/api/Parkinglot.js'
import { sysDictData } from '@/api/dictionaries.js'
export default {
  data() {
    return {
      isShowPayment: false,
      isShowloading: false,
      isShowData: false,
      tableData: [],
      inTime: undefined, // 开始时间
      outTime: undefined, // 结束时间
      query: {
        // 查询条件
        current: 1,
        size: 10,
        pk_id: undefined,
        plateNo: undefined, // 车牌号
        payStatus: undefined, // 支付状态
        orderNo: undefined, // 订单编号
        inTime: undefined, // 开始时间
        outTime: undefined, // 结束时间
        sortField: 'order_time', // 订单时间order_time  订单金额order_total_money
        sortType: 'desc', // 排序类型 默认desc  asc升序 desc降序
      },
      total: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
      payStatusdd: [], // 订单支付状态
      cashzje: undefined, //总金额
      queryPaymentList: [], // 保存停车记录弹框数据
    }
  },
  created() {
    this.getTimeFn()
    this.findPkInfoSelect()
    this.sysDictData()
  },
  methods: {
    getTimeFn() {
      // 默认显示前两天的数据
      this.inTime =
        new Date(new Date().toLocaleDateString()).getTime() - 3600 * 1000 * 24
      this.outTime =
        new Date(new Date().toLocaleDateString()).getTime() +
        24 * 60 * 60 * 1000 -
        1
    },
    sysDictData() {
      sysDictData({ typeId: 85 }).then((res) => {
        //订单支付状态
        this.payStatusdd = res.data.data.filter(function (item) {
          return item.name != 'FREE'
        })
      })
    },
    inTimechange() {
      this.outTime = this.inTime + 3600 * 1000 * 24 * 7 - 1000
    },
    outTimechange() {
      if (this.inTime === null || this.inTime === undefined) {
        this.outTime = null
        return this.$message.warning('请选择开始时间')
      } else if (this.outTime < this.inTime) {
        this.outTime = this.inTime + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      } else if (this.outTime - this.inTime > 3600 * 1000 * 24 * 7) {
        this.outTime = this.inTime + 3600 * 1000 * 24 * 7 - 1000
        return this.$message.warning('不可选择比开始时间超过七天以外的日期')
      }
    },
    sortchange(column, prop, order) {
      if (column.prop == 'orderTime') {
        this.query.sortField = 'order_time'
      } else if (column.prop == 'orderTotalMoney') {
        this.query.sortField = 'order_total_money'
      }
      if (column.order == 'descending') {
        this.query.sortType = 'desc'
      } else if (column.order == 'ascending') {
        this.query.sortType = 'asc'
      }
      this.newOrderInfo()
    },
    // 计算两个时间差 dateBegin 开始时间
    timeFn(dateBegin, dateEnd) {
      //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
      var dateDiff = dateEnd * 1000 - dateBegin * 1000 //时间差的毫秒数时间戳
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)) //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)) //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)) //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000)
      var leave4 = leave3 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var minseconds = Math.round(leave4 / 1000)
      // var timeFn = "耗时："+dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒"+minseconds+"毫秒";
      var timeFn =
        dayDiff + '天 ' + hours + '小时 ' + minutes + ' 分钟' + seconds + ' 秒'
      return timeFn
    },
    newOrderInfo() {
      // 进出记录查询列表
      this.query.inTime = this.inTime
      this.query.outTime = this.outTime
      if (this.query.inTime && !this.query.outTime) {
        return this.$message.warning('请输入截止时间')
      } else if (!this.query.inTime && this.query.outTime) {
        return this.$message.warning('请输入开始时间')
      }
      if (this.query.inTime || this.query.outTime) {
        this.query.inTime = parseInt(this.query.inTime / 1000).toFixed(0)
        this.query.outTime = parseInt(this.query.outTime / 1000).toFixed(0)
      }
      newOrderInfo(this.query, { pk_id: this.query.pk_id }).then((res) => {
        this.total = res.data.data.total
        this.tableData = res.data.data.list
        orderQueryDataBill(this.query, { pk_id: this.query.pk_id }).then(
          (res) => {
            this.isShowData = true
            this.cashzje = res.data.data
          }
        )
      })
    },
    orderInfocx() {
      // 查询
      this.cashzje = undefined
      this.tableData = []
      this.total = undefined
      this.query.current = 1
      this.newOrderInfo()
    },
    orderInfocz() {
      this.tableData = []
      this.total = undefined
      this.query.current = 1
      this.query.size = 10
      this.cashzje = undefined
      // 重置
      this.query.pk_id = this.PkInfoSelect[0].id
      this.query.plateNo = undefined
      this.query.orderNo = undefined
      this.query.payStatus = undefined
      this.query.sortField='order_time'
      this.query.sortType= 'desc'
      this.getTimeFn();
      // this.inTime = undefined
      // this.outTime = undefined
      this.newOrderInfo()
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          this.query.pk_id = this.PkInfoSelect[0].id
          this.newOrderInfo()
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.query.size = val
      this.newOrderInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.query.current = val
      this.newOrderInfo()
    },
    orderInfoexcel() {
      exportAbnormalOrderinfo(this.query, { pk_id: this.query.pk_id })
        .then((res) => {
          if (res.data.code === '200') {
            this.isShowloading = false
            window.open(res.data.data)
          }
        })
        .catch((err) => {
          this.isShowloading = false
        })
    },
    findQuery(code, list) {
      // 翻译
      const item = list.find((item) => item.code == code)
      return item ? item.name : ''
    },
    findQueryzfzt(code, dncode, list) {
      // 翻译支付状态
      const item = list.find((item) => item.code == code)
      if (item) {
        if (item.code == 0 && dncode === 1) {
          return '城市大脑支付中'
        } else if (item.code == 0 && dncode !== 1) {
          return '未支付'
        } else if (item.code != 0) {
          return item.name
        }
      }
    },
    queryPayment(id) {
      // 支付日志
      selectByPayLogtk({ id: id }, { pk_id: this.query.pk_id }).then((res) => {
        if (res.data.code === '200') {
          this.queryPaymentList = res.data.data
          this.isShowPayment = true
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.txbj {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      width: 33.33333%;
      line-height: 40px;
      font-weight: 600;
      span {
        color: #409eff;
      }
      img {
        vertical-align: text-top;
      }
    }
  }
}
::v-deep .el-dialog__body {
  padding-top: 0px;
}
</style>
